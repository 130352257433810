<template>
    <div class="section  login-2 acclog">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 logbx1">
                    <div class="form-section">
                        <div class="heading_main text_align_left">
                            <h2><span v-html="pageTitle"></span> </h2>
                        </div>
                        <form action="" method="POST" id="reset-frm" @submit.prevent="resetFormSubmit" autocomplete="off">
                            <input type="hidden" name="redirect" value="" />
                            <div class="form-group form-box" id="password_block">
                                <input type="password" :placeholder="placeholderText('password')" maxlength="25" autocomplete="off" v-model.trim="$v.formData.password.$model">
                                <i class="icon-material-outline-https"></i>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.required">Please Enter your password!</p>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.minLength">Password must be {{$v.formData.password.$params.minLength.min}} digits or long!</p>
                            </div>
                            <div class="form-group form-box" id="password_confirmation_block">
                                <input type="password" :placeholder="placeholderText('confirm_password')" name="password_confirmation" maxlength="25" autocomplete="off" v-model.trim="$v.formData.password_confirmation.$model">
                                <i class="icon-material-outline-https"></i>
                                <p class="form-error" v-if="!formStatus && $v.formData.password_confirmation.$error && !$v.formData.password_confirmation.sameAsPassword && !$v.formData.password_confirmation.required">Password and confirm Password should be same!</p>
                            </div>
                            
                            <div class="form-group mb-0 clearfix">
                                <button type="submit" class="btn-md btn-theme float-left gradient-btn full" :data-title="button">{{ button }}</button>
                            </div>  
                            <div class="resMssg text-center mt-3" v-html="formResponse"></div>         
                                    
                        </form>            
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            formData: {
                password_confirmation: '',
                password:''
            },
            formResponse: '',
            formStatus: true
        }
    },
    validations: {
        formData: {
            password: {required, minLength: minLength(6)},
            password_confirmation:{sameAsPassword: sameAs('password')},
        }
    },
    computed: {
        button() {
            return Translation.button[this.defaultLang].submit
        },
        pageTitle() {
            return Translation.pagetitle[this.defaultLang].reset_password
        }
    },
    created: function(){
        this.validateToken()
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        validateToken(){
            if(this.$route.query.token === null) {
                this.formResponse =`<span class="alert alert-danger">Token not found!</span>`
                return;
            }

            let params = {
                'token': this.$route.query.token
            }
            this.$store.dispatch('validateResetPasswordToken', params)
            .then(res=>{       
                if(res.status !== 200){
                    this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                }                
            }).catch(err=>{
                if(err.response.status === 500)
                    this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`
                else
                    this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
            })
        },
        resetFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){  
                this.formData.token = this.$route.query.token 
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$store.dispatch('resetPassword', this.formData)
                    .then(res=>{       
                        if(res.status === 200 && res.data.status === true){
                            this.resMsg = res.data.message;
                            this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`  
                            this.resetForm()
                            setTimeout(()=>{
                                this.$router.push('/account/login')
                            }, 2000);                        
                        }
                        else {
                            this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                        }
                        
                    }).catch(err=>{
                        if(err.response.status === 500)
                            this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`
                        else
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
                    })

                //this.togellingResponse();
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        }
    }
}
</script>
